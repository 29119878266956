import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getAjaxIsLoading } from "redux/slices/ajax/ajax.selectors";
import style from "./index.style";

const Loader = memo(() => {
  const isLoading = useSelector(getAjaxIsLoading());
  const LETSELL = process.env.REACT_APP_ENV === "Letsell";

  return (
    <div>
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            top: 0,
            zIndex: 2000,
            backgroundColor: "white",
          }}
        >
          <div className="logo-wrapper">
            <div className="logo">
              <div />
              <div />
              <div />
              <img
                className="animate-flicker"
                src={
                  LETSELL
                    ? "images/logo/letsell-logo-small.svg"
                    : "images/logo/BDroppy-logo-small.svg"
                }
                alt="logo"
              />
            </div>
          </div>
          <style>{style}</style>
        </div>
      )}
    </div>
  );
});

export default Loader;
