import { css } from "styled-components";

export default css`
  .logo-wrapper,
  .logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo img {
    max-width: 60px;
  }
  .logo {
    width: 170px;
    height: 170px;
    position: relative;
  }
  .logo svg,
  .logo div {
    width: 170px;
    height: 170px;
    position: absolute;
  }
  .logo div {
    width: 130px;
    height: 130px;
    margin: 20px;
    border-radius: 20%;
    transform: rotate(45deg);
    mix-blend-mode: multiply;
    -webkit-animation: 2s cubic-bezier(0.66, -0.7, 0.27, 1.6) infinite;
    animation: 2s cubic-bezier(0.66, -0.7, 0.27, 1.6) infinite;
  }
  .logo div:nth-child(1) {
    border: solid 3px white;
    -webkit-animation-name: rotate-1;
    animation-name: rotate-1;
  }
  .logo div:nth-child(2) {
    border: solid 3px #fd8d09;
    -webkit-animation-name: rotate-2;
    animation-name: rotate-2;
  }
  .logo div:nth-child(3) {
    border: solid 3px #3f9df4;
    -webkit-animation-name: rotate-3;
    animation-name: rotate-3;
  }
  @-webkit-keyframes rotate-1 {
    to {
      transform: rotate(135deg);
    }
  }
  @keyframes rotate-1 {
    to {
      transform: rotate(135deg);
    }
  }
  @-webkit-keyframes rotate-2 {
    to {
      transform: rotate(225deg);
    }
  }
  @keyframes rotate-2 {
    to {
      transform: rotate(225deg);
    }
  }
  @-webkit-keyframes rotate-3 {
    to {
      transform: rotate(315deg);
    }
  }
  @keyframes rotate-3 {
    to {
      transform: rotate(315deg);
    }
  }
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .animate-flicker {
    -webkit-animation: flickerAnimation 1.5s infinite;
    -moz-animation: flickerAnimation 1.5s infinite;
    -o-animation: flickerAnimation 1.5s infinite;
    animation: flickerAnimation 1.5s infinite;
  }
`;
